module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.bobsbusiness.co.uk","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"lang":"en","siteId":2228462,"cookiePolicyId":81410864,"consentOnContinuedBrowsing":false,"countryDetection":true,"cookiePolicyUrl":"https://www.bobsbusiness.co.uk/privacy-policy/","banner":{"acceptButtonDisplay":true,"customizeButtonDisplay":true,"position":"bottom","acceptButtonColor":"#cd205d","acceptButtonCaptionColor":"white","customizeButtonColor":"#1d253c","customizeButtonCaptionColor":"#ffffff","rejectButtonColor":"#1d253c","rejectButtonCaptionColor":"white","textColor":"#1d253c","backgroundColor":"#ffffff","rejectButtonDisplay":true}},"googleTagManagerOptions":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W7D27LR","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BB Brochure","short_name":"BB Brochure","start_url":"/","background_color":"#1D253C","theme_color":"#00A3C7","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1755afdbc82cfae63c64fadad2c9219e"},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cms.dev-bb.co.uk/graphql","verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
