// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-course-design-tsx": () => import("./../../../src/pages/course-design.tsx" /* webpackChunkName: "component---src-pages-course-design-tsx" */),
  "component---src-pages-courses-tsx": () => import("./../../../src/pages/courses.tsx" /* webpackChunkName: "component---src-pages-courses-tsx" */),
  "component---src-pages-customer-stories-tsx": () => import("./../../../src/pages/customer-stories.tsx" /* webpackChunkName: "component---src-pages-customer-stories-tsx" */),
  "component---src-pages-get-in-touch-tsx": () => import("./../../../src/pages/get-in-touch.tsx" /* webpackChunkName: "component---src-pages-get-in-touch-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-page-book-a-demo-tsx": () => import("./../../../src/pages/landing-page-book-a-demo.tsx" /* webpackChunkName: "component---src-pages-landing-page-book-a-demo-tsx" */),
  "component---src-pages-learning-management-system-tsx": () => import("./../../../src/pages/learning-management-system.tsx" /* webpackChunkName: "component---src-pages-learning-management-system-tsx" */),
  "component---src-pages-meet-the-team-tsx": () => import("./../../../src/pages/meet-the-team.tsx" /* webpackChunkName: "component---src-pages-meet-the-team-tsx" */),
  "component---src-pages-online-learning-tsx": () => import("./../../../src/pages/online-learning.tsx" /* webpackChunkName: "component---src-pages-online-learning-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-use-cases-tsx": () => import("./../../../src/pages/use-cases.tsx" /* webpackChunkName: "component---src-pages-use-cases-tsx" */),
  "component---src-templates-course-tsx": () => import("./../../../src/templates/course.tsx" /* webpackChunkName: "component---src-templates-course-tsx" */),
  "component---src-templates-customer-story-tsx": () => import("./../../../src/templates/customer-story.tsx" /* webpackChunkName: "component---src-templates-customer-story-tsx" */),
  "component---src-templates-landing-tsx": () => import("./../../../src/templates/landing.tsx" /* webpackChunkName: "component---src-templates-landing-tsx" */),
  "component---src-templates-products-tsx": () => import("./../../../src/templates/products.tsx" /* webpackChunkName: "component---src-templates-products-tsx" */),
  "component---src-templates-resource-iso-tsx": () => import("./../../../src/templates/resourceIso.tsx" /* webpackChunkName: "component---src-templates-resource-iso-tsx" */),
  "component---src-templates-resource-tsx": () => import("./../../../src/templates/resource.tsx" /* webpackChunkName: "component---src-templates-resource-tsx" */),
  "component---src-templates-services-tsx": () => import("./../../../src/templates/services.tsx" /* webpackChunkName: "component---src-templates-services-tsx" */),
  "component---src-templates-standalone-tsx": () => import("./../../../src/templates/standalone.tsx" /* webpackChunkName: "component---src-templates-standalone-tsx" */),
  "component---src-templates-use-case-tsx": () => import("./../../../src/templates/use-case.tsx" /* webpackChunkName: "component---src-templates-use-case-tsx" */)
}

